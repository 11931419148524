import { useRef, useMemo } from 'react';
import Plyr from "plyr-react";
import "plyr-react/plyr.css";


function VideoBlock({ video, onView }) {

  const ref = useRef();
  // useEffect(() => {
  //   const callView = (event) => {
  //     if (ref.current.plyr.currentTime + 10 > video.duration) {
  //       ref.current.plyr.off('timeupdate', callView)
  //       onView();
  //     }
  //   };

  //   if (video && onView) {
  //     console.log(ref);
  //     console.log(ref.current);
  //     console.log(ref.current.plyr);
  //     ref.current.plyr.off('timeupdate', callView)
  //     ref.current.plyr.on('timeupdate', callView);
  //   }
  // }, [onView, video]);

  const source = useMemo(() => {
    const result = {
      type: "video",
      sources: [],
      tracks: [],
    };

    for (let size of video.sizes) {
      result.sources.push({
        src: size.video_file,
        size: size.quality.slice(0, -1),
        type: "video/mp4"
      });
    }
    if (video.subtitles_file) {
      result.tracks.push({
        src: video.subtitles_file,
        kind: "subtitles",
        srclang: "uk",
        label: "Ukranian",
        default: true
      })
    }
    if (video.preview_file) {
      result.poster = video.preview_file;
    }
    return result
  }, [video]);

  if ( ! source.sources.length) {
    return (
      <h2>Not video</h2>
    );
  }

  return (
    <Plyr ref={ref} source={source} crossOrigin="anonymus" />
  );
}

export default VideoBlock;
